export const formatDate = (epoch?: number) => {
  if (!epoch) return;
  const date = new Date(epoch);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}.${month}.${day} - ${hours}:${minutes}`;
};

export const dateStringToEpoch = (
  dateString: string,
  timeString: string,
): number => {
  const [year, month, day] = dateString.split('-').map(Number);
  const [hours, minutes] = timeString.split(':').map(Number);

  // Months are 0 indexed in JavaScript's Date object, so we need to subtract 1
  const date = new Date(year, month - 1, day, hours, minutes);

  return date.getTime(); // returns the epoch timestamp in milliseconds
};

export function groupBy<T>(
  list: T[],
  keyGetter: (item: T) => string,
): {[key: string]: T[]} {
  const grouped: {[key: string]: T[]} = {};

  list.forEach((item) => {
    const key = keyGetter(item);
    if (!grouped[key]) {
      grouped[key] = [];
    }
    grouped[key].push(item);
  });

  return grouped;
}
