// and extend them!
import {createTheme} from '@mui/material';

import {ThemeOptions} from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#e6a4b4',
    },
    secondary: {
      main: '#f3d7ca',
    },
    background: {
      default: '#f5eee6',
      paper: '#fff8e3',
    },
  },
};
export const muiTheme = createTheme(themeOptions);
