// Initialize Firebase
import {httpsCallable} from 'firebase/functions';
import {useCallback} from 'react';
import useFirebase from './useFirebase';
import {useAppDispatch} from '../store/hooks';
import {Occasion, Reservation, Room, User} from '../models/Models';
import {
  setOccasions,
  setReservation,
  setReservations,
  setRoom,
  setRooms,
} from '../store/reservationSlice';
import {openError, openSuccess} from '../store/feedbackSlice';
import {setBusy} from '../store/busySlice';

const useUtils = () => {
  const {functions} = useFirebase();
  const dispatch = useAppDispatch();

  const call = useCallback(
    async (method: string, param: unknown = null) => {
      try {
        dispatch(setBusy(true));
        const {data} = await httpsCallable(functions, method)(param);
        dispatch(openSuccess('Sikerült! ≽^•⩊•^≼ A cica vidám!'));
        return data;
      } catch (error) {
        console.error('🔥 call', error, method, param);
        dispatch(
          openError('Valami elromlott. ฅ^•ﻌ•^ฅ ez a cica most szomorú.'),
        );
        return {data: undefined};
      } finally {
        dispatch(setBusy(false));
      }
    },
    [functions],
  );

  const get = useCallback(
    async (method: string, param: unknown = null) => {
      try {
        dispatch(setBusy(true));
        const {data} = await httpsCallable(functions, method)(param);
        return data;
      } catch (error) {
        console.error(`🔥 get '${error}'`, error, method, param);
        if (error != 'FirebaseError: unauthenticated') {
          dispatch(
            openError('Valami elromlott. ฅ^•ﻌ•^ฅ ez a cica most szomorú.'),
          );
        }
        return {data: undefined};
      } finally {
        dispatch(setBusy(false));
      }
    },
    [functions],
  );

  /**
   * public methods
   */

  const getRooms = useCallback(async () => {
    const result = await get('api/rooms');
    const rooms = result as [Room];
    dispatch(setRooms(rooms));
    return rooms;
  }, [get]);

  const getRoom = useCallback(
    async (roomId: string) => {
      const result = await get('api/room', {roomId});
      const room = result as Room;
      dispatch(setRoom(room));
      return room;
    },
    [get],
  );

  const getOccasions = useCallback(
    async (roomId: string) => {
      const result = await get('api/occasions', {roomId});
      const occasions = result as [Occasion];
      dispatch(setOccasions(occasions));
      return occasions;
    },
    [get],
  );

  const getReservations = useCallback(async () => {
    const result = await get('api/reservations');
    const reservations = result as [Reservation];
    dispatch(setReservations(reservations));
    return reservations;
  }, [get]);

  const getReservation = useCallback(
    async (reservationId: string) => {
      const result = await get('api/reservation', {reservationId});
      const reservation = result as Reservation;
      dispatch(setReservation(reservation));
      return reservation;
    },
    [get],
  );

  const bookOccasion = useCallback(
    async (occasion: Occasion) => {
      await call('api/book-occasion', occasion);
    },
    [call],
  );

  const cancelReservation = useCallback(
    async (reservation: Reservation) => {
      await call('api/cancel-reservation', reservation);
    },
    [call],
  );

  /**
   * User methods
   */

  const updateUserProfile = useCallback(
    async (user: User) => {
      return await call('api/update-user-profile', user);
    },
    [call],
  );

  /**
   * Admin methods
   */

  const createOccasion = useCallback(
    async (param: {date: number; time: string; roomId: string}) => {
      return await call('admin/create-occasion', param);
    },
    [call],
  );

  const adminGetUsers = useCallback(async () => {
    return await get('admin/get-users');
  }, [get]);

  const adminSetUser = useCallback(async () => {
    return await call('admin/set-user');
  }, [call]);

  return {
    getRooms,
    getRoom,
    getOccasions,
    getReservations,
    getReservation,
    createOccasion,
    adminGetUsers,
    adminSetUser,
    updateUserProfile,
    bookOccasion,
    cancelReservation,
  };
};
export default useUtils;
