import React from 'react';
import {Typography} from '@mui/material';

const Policies = () => {
  return (
    <>
      <Typography variant="h3">Adatvédelmi Tájékoztató</Typography>
      <Typography>
        Üdvözöljük a Babamocorgó weboldalon! Az Ön személyes adatainak védelme
        fontos számunkra. Ez a Tájékoztató ismerteti, hogy hogyan gyűjtjük,
        használjuk és védjük a személyes adatokat, amikor Ön a
        https://babamocorgo.hu oldalt használja.
      </Typography>
      <Typography variant="h4">Adatgyűjtés és használat</Typography>
      <Typography>
        A Babamocorgó a Google Firebase platformot használja a weboldal
        hostingjára és az adatok tárolására a Firebase Firestore
        szolgáltatásban. Személyes adatokat csak abban az esetben gyűjtünk, ha
        Ön közvetlenül szolgáltatja azokat nekünk, például űrlapok kitöltésekor.
        Ezek az adatok a weboldal működéséhez szükségesek, és nem osztjuk meg
        harmadik féllel.
      </Typography>
      <Typography variant="h4">Cookie-k</Typography>
      <Typography>
        Weboldalunk csak a működéshez szükséges cookie-kat használ. Ezek a
        cookie-k nem gyűjtenek személyes adatokat, és csak az oldal működésének
        biztosítására szolgálnak.
      </Typography>
      <Typography variant="h4">Adatbiztonság</Typography>
      <Typography>
        A Babamocorgó elkötelezett amellett, hogy megvédje az Ön személyes
        adatait. Megfelelő fizikai, technológiai és szervezeti intézkedéseket
        alkalmazunk az adatok biztonságának megőrzése érdekében.
      </Typography>
      <Typography variant="h4">Az érintettek jogai</Typography>
      <Typography>
        Az Európai Unió Általános Adatvédelmi Rendelete (GDPR) értelmében Ön
        jogosult hozzáférni személyes adataihoz, kérheti azok helyesbítését,
        törlését vagy adatkezelés korlátozását. Ha bármilyen kérdése vagy
        aggálya van az adatkezeléssel kapcsolatban, kérjük, lépjen kapcsolatba
        velünk az alábbi elérhetőségeken.
      </Typography>
      <Typography variant="h4">Kapcsolat</Typography>
      <Typography>
        Ha bármilyen kérdése van az adatvédelmi gyakorlatunkkal kapcsolatban,
        kérjük, lépjen kapcsolatba velünk a babamocorgo@gmail.com címen.
      </Typography>
      <Typography>
        A Google számára engedélyezett alkalmazásokat a következő linkre
        kattintva módosíthatja{' '}
        <a href="https://security.google.com/settings/security/permissions">
          Third-party apps & services
        </a>
      </Typography>
    </>
  );
};
export default Policies;
