import React from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import {FormikHelpers, FormikValues, useFormik} from 'formik';
import {useParams} from 'react-router-dom';
import {useAppSelector} from '../store/hooks';
import {dateStringToEpoch, formatDate} from '../utils';
import useUtils from '../hooks/useUtils';

interface Values {
  date: string;
  time: string;
}

const NewOccasion = () => {
  const {roomId = ''} = useParams();
  const {room, occasions} = useAppSelector((state) => state.reservation);
  const {createOccasion, getOccasions} = useUtils();

  const date = new Date().toISOString().split('T')[0];
  const time = '09:00';

  const formik = useFormik({
    initialValues: {
      date,
      time,
    },
    onSubmit: async (
      values: FormikValues,
      {setSubmitting}: FormikHelpers<Values>,
    ) => {
      const date = dateStringToEpoch(values.date, values.time);

      // c onsole.log(
      //   '🤡',
      //   values,
      //   date,
      //   new Date(date).toISOString(),
      //   new Date(date).toLocaleDateString('hu'),
      //   new Date(date).toLocaleTimeString('hu'),
      // );

      await createOccasion({date, time, roomId});
      getOccasions(roomId);

      //pop up

      setSubmitting(false);
    },
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Card>
          <CardContent>
            <Typography variant="h4" mb={2} gutterBottom>
              {room?.name}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="date"
                  name="date"
                  label="Dátum"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formik.values.date}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.date && Boolean(formik.errors.date)}
                  helperText={formik.touched.date && formik.errors.date}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="time"
                  name="time"
                  label="Időpont"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formik.values.time}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.time && Boolean(formik.errors.time)}
                  helperText={formik.touched.time && formik.errors.time}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button color="primary" type="submit" variant="contained">
              Létrehozás
            </Button>
          </CardActions>
        </Card>
      </form>
      {occasions &&
        occasions
          .slice()
          .sort((a, b) => b.date - a.date)
          .map((o) => <div key={o.id}>{formatDate(o.date)}</div>)}
    </>
  );
};
export default NewOccasion;
