import React from 'react';
import {
  Box,
  Button,
  Divider,
  Stack,
  Tab,
  TextField,
  Typography,
} from '@mui/material';
import useAuth from '../hooks/useAuth';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import GoogleSignInButton from '../components/GoogleSignInButton/GoogleSignInButton';
import Panel from '../components/Panel';
import {GitHub} from '@mui/icons-material';
import {Link} from 'react-router-dom';

const Login = () => {
  const {login, signup, loginGoogleOAUTH, loginGitOAUTH} = useAuth();
  const [value, setValue] = React.useState('1');
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const loginHandle = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login({
      email,
      password,
    });
  };
  const signupHandle = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    signup({
      email,
      password,
      name,
    });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setName('');
    setEmail('');
    setPassword('');
    setValue(newValue);
  };

  const loginPanel = (
    <form onSubmit={loginHandle}>
      <Stack spacing={2}>
        <TextField
          required
          id="email"
          label="E-mail"
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          id="password"
          label="Jelszó"
          type="password"
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
        />

        <Button variant="contained" type="submit">
          Bejelentkezés
        </Button>
      </Stack>
    </form>
  );

  const singinPanel = (
    <form onSubmit={signupHandle}>
      <Stack spacing={2}>
        <TextField
          required
          id="name"
          label="Név"
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          required
          id="email"
          label="E-mail"
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          id="password"
          label="Jelszó"
          type="password"
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
        />

        <Button variant="contained" type="submit">
          Regisztráció
        </Button>
      </Stack>
    </form>
  );

  return (
    <>
      <Panel>
        <Box sx={{width: '100%', typography: 'body1', height: '50vh'}}>
          <TabContext value={value}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Bejelentkezés" value="1" />
                <Tab label="Regisztráció" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">{loginPanel}</TabPanel>
            <TabPanel value="2">{singinPanel}</TabPanel>
          </TabContext>
        </Box>
        <Divider />
        <Box sx={{padding: '1rem'}}>
          <GoogleSignInButton onClick={loginGoogleOAUTH} />
        </Box>
        <Box sx={{padding: '1rem'}} onClick={loginGitOAUTH}>
          <Button variant="contained" color="info">
            <GitHub></GitHub>
            <span>&nbsp;B3l3p3s GitHub OAUTH</span>
          </Button>
        </Box>
      </Panel>
      <Box sx={{position: 'absolute', right: 0, bottom: 0}}>
        <Typography variant="caption">
          <Link
            style={{color: 'inherit', textDecoration: 'none'}}
            to={'/policies'}
          >
            Adatvédelmi tájékoztató
          </Link>
        </Typography>
      </Box>
    </>
  );
};
export default Login;
