import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import {connectFunctionsEmulator, getFunctions} from 'firebase/functions';
import {useMemo} from 'react';
import {useAppSelector} from '../store/hooks';

const useFirebase = () => {
  const {user} = useAppSelector((state) => state.auth);
  const app = useMemo(
    () =>
      initializeApp({
        projectId: 'babamocorgo-baba',
        apiKey: 'AIzaSyDBKiU9Sb4ARQDUeTATgNWBOimeYp7s2zc',
        // authDomain: 'babamocorgo-baba.firebaseapp.com',
        authDomain: 'babamocorgo.hu',
        appId: '1:403463290926:web:d02fc53dca4c150fc31fbf',
        measurementId: 'G-L9X3TT1S5J',
        /*
        apiKey: 'AIzaSyDBKiU9Sb4ARQDUeTATgNWBOimeYp7s2zc',
        authDomain: 'babamocorgo-baba.firebaseapp.com',
        databaseURL:
          'https://babamocorgo-baba-default-rtdb.europe-west1.firebasedatabase.app',
        projectId: 'babamocorgo-baba',
        storageBucket: 'babamocorgo-baba.appspot.com',
        messagingSenderId: '403463290926',
        */
      }),
    [user],
  );
  const anal = useMemo(() => getAnalytics(app), [app]);

  const functions = useMemo(() => {
    const functions = getFunctions(app);
    // connectFunctionsEmulator(functions, '127.0.0.1', 5001);
    return functions;
  }, [app]);

  return {
    app,
    anal,
    functions,
  };
};
export default useFirebase;
