import {PayloadAction, createSlice} from '@reduxjs/toolkit';

export interface BusyState {
  busy: boolean;
}

const initialState: BusyState = {
  busy: false,
};

export const busySlice = createSlice({
  name: 'busy',
  initialState,
  reducers: {
    setBusy: (state, action: PayloadAction<boolean>) => {
      state.busy = action.payload;
    },
  },
});

export const {setBusy} = busySlice.actions;

export default busySlice.reducer;
