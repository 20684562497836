import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
// import {User} from '../models/User';
import {User} from '../models/Models';
//import firebase from 'firebase/compat/app';

export interface AuthState {
  user?: User;
  loaded: boolean;
}

const initialState: AuthState = {
  user: undefined,
  loaded: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | undefined>) => {
      state.user = action.payload;
    },
    setLoaded: (state) => {
      state.loaded = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const {setUser, setLoaded} = authSlice.actions;

export default authSlice.reducer;
