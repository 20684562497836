import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {Occasion, Reservation, Room} from '../models/Models';

export interface ReservationState {
  rooms?: [Room];
  occasions?: [Occasion];
  room?: Room;
  reservations?: [Reservation];
  reservation?: Reservation;
}

const initialState: ReservationState = {
  rooms: undefined,
  occasions: undefined,
  room: undefined,
  reservations: undefined,
  reservation: undefined,
};

export const reservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    setRooms: (state, action: PayloadAction<[Room] | undefined>) => {
      state.rooms = action.payload;
    },
    setOccasions: (state, action: PayloadAction<[Occasion] | undefined>) => {
      state.occasions = action.payload?.sort((o) => o.date);
    },
    setRoom: (state, action: PayloadAction<Room | undefined>) => {
      state.room = action.payload;
    },
    setReservations: (
      state,
      action: PayloadAction<[Reservation] | undefined>,
    ) => {
      state.reservations = action.payload;
    },
    setReservation: (state, action: PayloadAction<Reservation | undefined>) => {
      state.reservation = action.payload;
    },
  },
});

export const {
  setRooms,
  setOccasions,
  setRoom,
  setReservations,
  setReservation,
} = reservationSlice.actions;

export default reservationSlice.reducer;
