import React, {useMemo} from 'react';
import {useAppSelector} from './store/hooks';
import useInitialisation from './hooks/useInitialisation';
import AppRouter from './AppRouter';
import {RouterProvider, createBrowserRouter, redirect} from 'react-router-dom';
import Login from './screens/Login';
import {Backdrop, CircularProgress} from '@mui/material';
import Policies from './screens/Policies';

const LoginRouter = () => {
  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          path: '/login',
          element: <Login />,
        },
        {
          path: '/policies',
          element: <Policies />,
        },
        {
          path: '*',
          loader: () => redirect('/login'),
        },
      ]),
    [],
  );
  return <RouterProvider router={router} />;
};

const InitialisingApp = () => {
  useInitialisation();

  const {loaded, user} = useAppSelector((state) => state.auth);

  return (
    <>
      {!loaded && (
        <>
          <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
      {loaded && !user && <LoginRouter />}
      {loaded && user && <AppRouter />}
    </>
  );
};
export default InitialisingApp;
