import {Card} from '@mui/material';
import React, {ReactNode} from 'react';
import styled from 'styled-components';

const PanelOuter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
const PanelInner = styled.div`
  margin: 2rem;

  @media screen {
    width: 600px;
  }
`;

type PanelProps = {
  children: ReactNode;
};

const Panel = (props: PanelProps) => {
  return (
    <PanelOuter>
      <PanelInner>
        <Card>{props.children}</Card>
      </PanelInner>
    </PanelOuter>
  );
};

export default Panel;
