// Reservation.tsx

import React, {useState} from 'react';
import {useAppSelector} from '../store/hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Paper,
  Typography,
  Zoom,
} from '@mui/material';
import {formatDate} from '../utils';
import {EventBusy} from '@mui/icons-material';
import {TransitionProps} from '@mui/material/transitions';
import {LeftAlignBox} from '../components/Styles';
import useUtils from '../hooks/useUtils';
import {useNavigate} from 'react-router-dom';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Zoom style={{transitionDelay: '500ms'}} ref={ref} {...props} />;
});

const Reservation = () => {
  const {reservation} = useAppSelector((state) => state.reservation);
  const {cancelReservation} = useUtils();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleApproved = async () => {
    setOpen(false);
    reservation && (await cancelReservation(reservation));
    navigate('/');
  };
  if (!reservation) return <></>;
  return (
    <>
      <Paper sx={{padding: 5}}>
        <Typography variant="h2">{reservation?.occasion.room.name}</Typography>
        <Typography variant="h3">
          {formatDate(reservation?.occasion.date)}
        </Typography>
        <LeftAlignBox>
          <Fab variant="extended" sx={{marginTop: 5}} onClick={handleClick}>
            <EventBusy sx={{mr: 1}} />
            Lemondás
          </Fab>
        </LeftAlignBox>
      </Paper>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Visszaigazolás?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Biztos szeretnéd lemondani az időpontot?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="warning" onClick={handleClose}>
            Mégsem
          </Button>
          <Button variant="contained" color="error" onClick={handleApproved}>
            Rendben
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Reservation;
