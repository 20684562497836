import React, {useState} from 'react';
import {Button} from '@mui/material';
import useUtils from '../hooks/useUtils';

const Admin = () => {
  const {adminGetUsers, adminSetUser} = useUtils();
  const [bar, setBar] = useState<unknown>(undefined);
  return (
    <div>
      <h2>admin</h2>
      <Button
        variant="outlined"
        onClick={async () => {
          setBar(await adminGetUsers());
        }}
      >
        adminGetUsers
      </Button>
      <Button
        variant="outlined"
        onClick={async () => {
          setBar(await adminSetUser());
        }}
      >
        setUser
      </Button>
      <pre>{JSON.stringify(bar, null, 2)}</pre>
    </div>
  );
};
export default Admin;
