import React, {useMemo} from 'react';
import useUtils from './hooks/useUtils';
import {RouterProvider, createBrowserRouter, redirect} from 'react-router-dom';
import Layout from './components/Layout';
import Home from './screens/Home';
import Error from './screens/Error';
import useAuth from './hooks/useAuth';
import Rooms from './screens/Rooms';

import Occasions from './screens/Occasions';
import NewOccasion from './screens/NewOccasion';
import Admin from './screens/Admin';
import Reservation from './screens/Reservation';
import Policies from './screens/Policies';

const AppRouter = () => {
  const {logout} = useAuth();
  const {getRooms, getOccasions, getRoom, getReservations, getReservation} =
    useUtils();

  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          path: '/',
          element: <Layout />,
          children: [
            {
              index: true,
              loader: () => redirect('/home'),
            },
            {
              path: '/home',
              element: <Home />,
              loader: () => getReservations(),
            },
            {
              path: 'location',
              element: <Rooms />,
              loader: () => getRooms(),
            },
            {
              path: 'occasions/:roomId',
              element: <Occasions />,
              loader: ({params}) => getOccasions(params.roomId as string),
            },
            {
              path: 'new-occasion/:roomId',
              element: <NewOccasion />,
              loader: async ({params}) => {
                const roomId = params.roomId as string;
                await getRoom(roomId);
                return await getOccasions(roomId);
              },
            },
            {
              path: 'reservation/:reservationId',
              element: <Reservation />,
              loader: ({params}) =>
                getReservation(params.reservationId as string),
            },
            {
              path: '/admin',
              element: <Admin />,
            },
            {
              path: '/policies',
              element: <Policies />,
            },
          ],
        },
        {
          path: '/login',
          loader: () => redirect('/'),
        },
        {
          path: '/logout',
          loader: async () => {
            await logout();
            return redirect('/');
          },
        },
        {
          path: '*',
          element: <Error />,
        },
      ]),
    [getRooms, getOccasions, getRoom, getReservations, getReservation],
  );

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default AppRouter;
