import React from 'react';
import {Link, Outlet} from 'react-router-dom';
import Header from './Header';
import {
  Alert,
  AlertColor,
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Snackbar,
  Typography,
} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../store/hooks';
import {MessageSeverity, hideMessage} from '../store/feedbackSlice';

const Layout = () => {
  const {busy} = useAppSelector((state) => state.busy);
  const {open, message, severity} = useAppSelector((state) => state.feedback);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(hideMessage());
  };
  return (
    <>
      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={busy}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Header />
      <Container sx={{marginTop: '2rem'}}>
        <Outlet></Outlet>
      </Container>
      <Box sx={{position: 'fixed', right: 0, bottom: 0}}>
        <Typography variant="caption">
          <Link
            style={{color: 'inherit', textDecoration: 'none'}}
            to={'/policies'}
          >
            Adatvédelmi tájékoztató
          </Link>
        </Typography>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{horizontal: 'center', vertical: 'top'}}
      >
        <Alert
          severity={MessageSeverity[severity] as AlertColor}
          variant="filled"
          sx={{width: '100%'}}
          onClose={handleClose}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Layout;
