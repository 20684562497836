import React from 'react';
import {Box, Card, CardContent, Grid, Typography} from '@mui/material';
import {useAppSelector} from '../store/hooks';
import {formatDate} from '../utils';
import {Clickable} from '../components/Styles';

const Home = () => {
  const {user} = useAppSelector((state) => state.auth);
  const {reservations} = useAppSelector((state) => state.reservation);

  return (
    <div>
      <h1>🚧 Tesztüzem. 🚜</h1>
      <h2>Hello, {user?.displayName}</h2>
      <h3>Új időpont foglalása</h3>
      <Clickable href="/location">
        <Box
          sx={{
            borderRadius: 5,
            border: 2,
            padding: 1,
            borderColor: 'primary.main',
            textAlign: 'center',
          }}
        >
          <Typography color="primary" variant="h3">
            időpontfoglalás
          </Typography>
        </Box>
      </Clickable>
      {reservations && (reservations?.length ?? 0) > 0 && (
        <>
          <h3>Foglalásaid</h3>
          <Grid container spacing={2}>
            {reservations
              .slice()
              .sort((a, b) => a.occasion.date - b.occasion.date)
              .map((reservation) => (
                <Grid item xs={12} md={6} key={reservation.id}>
                  <Clickable href={`/reservation/${reservation.id}`}>
                    <Card>
                      <CardContent>
                        <Typography variant="h4" gutterBottom>
                          {reservation.occasion.room.name}
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                          {formatDate(reservation.occasion.date)}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Clickable>
                </Grid>
              ))}
          </Grid>
        </>
      )}
    </div>
  );
};
export default Home;
