import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Grid,
  Typography,
  Zoom,
} from '@mui/material';
import {useAppSelector} from '../store/hooks';
import {ArrowCircleRight} from '@mui/icons-material';
import {formatDate} from '../utils';
import React, {useState} from 'react';
import {TransitionProps} from '@mui/material/transitions';
import useUtils from '../hooks/useUtils';
import {Occasion} from '../models/Models';
import {useNavigate} from 'react-router-dom';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Zoom style={{transitionDelay: '500ms'}} ref={ref} {...props} />;
});

const Occasions = () => {
  const {bookOccasion} = useUtils();
  const {occasions} = useAppSelector((state) => state.reservation);
  const [open, setOpen] = useState(false);
  const [selectedOccasion, setSelectedOccasion] = useState<Occasion>();
  const navigate = useNavigate();

  const handleClose = () => {
    setSelectedOccasion(undefined);
    setOpen(false);
  };

  const handleClick = (occasion: Occasion) => {
    setSelectedOccasion(occasion);
    setOpen(true);
  };

  const handleApprovedSelection = async () => {
    setOpen(false);
    selectedOccasion && (await bookOccasion(selectedOccasion));
    navigate('/');
  };

  return (
    <>
      <Grid container spacing={2}>
        {occasions
          ?.slice()
          .sort((a, b) => a.date - b.date)
          .map((occasion) => {
            const freeNr = Math.max(
              occasion.room.capacity - (occasion.reserved ?? 0),
              0,
            );
            return (
              <Grid item xs={12} md={6} key={occasion.id}>
                <Card>
                  <CardContent>
                    <Typography variant="h4" gutterBottom>
                      {occasion.room.name}
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                      {formatDate(occasion.date)}
                    </Typography>

                    <Chip label={`${freeNr} szabad hely van még`} />
                  </CardContent>
                  <CardActions>
                    <Fab
                      color="primary"
                      variant="extended"
                      onClick={() => handleClick(occasion)}
                      disabled={freeNr <= 0}
                    >
                      <ArrowCircleRight sx={{mr: 1}} />
                      Tovább
                    </Fab>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
      </Grid>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Szeretnél időpontot foglalni?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Ha szeretnél időpontot foglalni{' '}
            <b>
              {selectedOccasion?.room.name} {formatDate(selectedOccasion?.date)}
            </b>{' '}
            alkalomra, kérlek nyomd meg az Rendben gombot
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="warning" onClick={handleClose}>
            Mégsem
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleApprovedSelection}
          >
            Rendben
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Occasions;
