import React from 'react';
import ReactDOM from 'react-dom/client';
// import reportWebVitals from './reportWebVitals';
import {store} from './store/store';
import {Provider} from 'react-redux';
import {muiTheme} from './components/Theme';
import {CssBaseline, ThemeProvider} from '@mui/material';
import InitialisingApp from './InitialisingApp';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <InitialisingApp />
    </ThemeProvider>
  </Provider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(c onsole.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportsWebVitals();
