import {onAuthStateChanged} from 'firebase/auth';
import {useEffect} from 'react';
import useAuth from './useAuth';
import {useAppDispatch} from '../store/hooks';
import {setLoaded, setUser} from '../store/authSlice';
import {User} from '../models/Models';

const useInitialisation = () => {
  const {auth} = useAuth();
  const dispatch = useAppDispatch();

  // const [init, setInit] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      // if (init) return;
      // setInit(true);

      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // await getUser();\
        let roles: [string?] = [];
        const reloadUserInfo = (user as unknown as never)['reloadUserInfo'] as {
          customAttributes: string;
        };
        if (reloadUserInfo?.customAttributes) {
          const rolesParsed = JSON.parse(reloadUserInfo.customAttributes);

          roles = rolesParsed['roles']
            ? [...(rolesParsed['roles'] as [string])]
            : [];
        }
        const isAdmin: boolean = roles?.includes('admin') ?? false;

        const safeUser: User = {
          displayName: user.displayName ?? '',
          uid: user.uid,
          photoURL: user.photoURL ?? '',
          email: user.email ?? '',
          isAdmin,
          roles,
        };
        dispatch(setUser(safeUser));
      } else {
        dispatch(setUser(undefined));
      }
      dispatch(setLoaded());
    });
  }, [auth]);
};
export default useInitialisation;
