import React from 'react';
import {ReactNode} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

const ClickableInner = styled.div`
  cursor: pointer;
`;

type ClickableProps = {
  children: ReactNode;
  onClick?: () => void;
  href?: string;
};

export const Clickable = ({children, onClick, href}: ClickableProps) => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    if (href) {
      navigate(href);
    } else {
      onClick && onClick();
    }
  };
  return <ClickableInner onClick={onClickHandler}>{children}</ClickableInner>;
};

export const LeftAlignBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

// export const Panel = css`
//   background-color: ${(props) => props.theme.colors.panelBackground};
//   border-radius: ${(props) => props.theme.borderRadius.panel};
//   padding: 1.5em;
//   margin: 1.5rem;
//   box-shadow: 0 1px 5px #0001;
// `;
// export const Footer = styled.footer`
//   height: 1rem;
//   font-size: 0.5rem;
//   display: flex;
//   flex-direction: row-reverse;
//   align-items: flex-end;
//   //border: 1px solid pink;
// `;
// export const Button = styled.button`
//   padding: 0.5em;
//   background-color: ${(props) => props.theme.colors.primary};
//   color: ${(props) => props.theme.colors.lightText};
//   border: 2px solid ${(props) => props.theme.colors.primaryDark};
//   border-radius: 0.5em;
// `;
// export const Input = styled.input`
//   padding: 0.3em 0.5rem;
//   background-color: ${(props) => props.theme.colors.inputBackground};
//   font-size: 1rem;
//   color: ${(props) => props.theme.colors.darkText};
//   border: 1px solid ${(props) => props.theme.colors.inputBorder};
//   border-radius: ${(props) => props.theme.borderRadius.control};

//   &:focus-visible {
//     //border-color: ${(props) => props.theme.colors.inputBorderFocus};
//     box-shadow: 0 1px 2px #0002;
//   }
// `;
// export const Separator = styled.div`
//   border-top: 1px solid #888;
//   margin: 20px 0 40px 0;
// `;
// export const Section = styled.div`
//   padding: 0.1em 0;
// `;
// export const Label = styled.label`
//   display: inline-block;
//   width: 15em;
//   font-weight: bold;
// `;
