import {
  GithubAuthProvider,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  getRedirectResult,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  signOut,
} from 'firebase/auth';
import {useCallback, useMemo} from 'react';
import useFirebase from './useFirebase';
import {useAppSelector} from '../store/hooks';
import useUtils from './useUtils';
import {User} from '../models/Models';

const useAuth = () => {
  const {app} = useFirebase();
  const {user, loaded} = useAppSelector((state) => state.auth);
  const {updateUserProfile} = useUtils();

  // Initialize Firebase Authentication and get a reference to the service
  const auth = useMemo(() => {
    const auth = getAuth(app);
    auth.languageCode = 'hu';
    /*    connectAuthEmulator(auth, "http://localhost:9099", {
            disableWarnings: false,
            });*/
    return auth;
  }, [app]);

  const login = useCallback(
    async ({email, password}: {email: string; password: string}) => {
      const user = await signInWithEmailAndPassword(auth, email, password);
      return user;
    },
    [auth],
  );

  const logout = useCallback(async () => {
    await signOut(auth);
  }, [auth]);

  const signup = useCallback(
    async ({
      name,
      email,
      password,
    }: {
      name: string;
      email: string;
      password: string;
    }) => {
      try {
        const user = await createUserWithEmailAndPassword(
          auth,
          email,
          password,
        );
        const userProfileRequestData: User = {
          displayName: name,
          email,
          uid: '',
          isAdmin: false,
        };
        await updateUserProfile(userProfileRequestData);

        //logout
        await signOut(auth);
        //login w/ email and password again
        await signInWithEmailAndPassword(auth, email, password);

        return user;
      } catch (e) {
        console.error(e);
      }
    },
    [auth],
  );

  const loginWithGoogleRedirect = useCallback(async () => {
    try {
      const provider = new GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      const signInWithRedirectResult = await signInWithRedirect(auth, provider);

      const getRedirectResultResult = await getRedirectResult(auth);
    } catch (e) {
      console.error('loginWithGoogleRedirect error', e);
    }
    return;
  }, [auth]);

  const loginWithGooglePopup = useCallback(async () => {
    try {
      const provider = new GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      provider.setDefaultLanguage('hu');
      provider.setCustomParameters({
        prompt: 'select_account',
      });
      const {user} = await signInWithPopup(auth, provider);
    } catch (e) {
      console.error('loginWithGooglePopup error', e);
    }
    return;
  }, [auth]);

  const loginGoogleOAUTH = async () => {
    await loginWithGooglePopup();
    // await loginWithGoogleRedirect();
  };

  const loginGitOAUTH = useCallback(async () => {
    try {
      const provider = new GithubAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      provider.setDefaultLanguage('hu');
      provider.setCustomParameters({
        prompt: 'select_account',
      });
      const {user} = await signInWithPopup(auth, provider);
    } catch (e) {
      console.error('loginGitOAUTH error', e);
    }
    return;
  }, [auth]);

  return {
    auth,
    user,
    loaded,
    login,
    logout,
    signup,
    // loginWithGoogleRedirect,
    // loginWithGooglePopup,
    loginGoogleOAUTH,
    loginGitOAUTH,
  };
};
export default useAuth;
