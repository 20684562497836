import {PayloadAction, createSlice} from '@reduxjs/toolkit';

export enum MessageSeverity {
  success,
  info,
  warning,
  error,
}

export interface FeedbackState {
  message?: string;
  severity: MessageSeverity;
  open: boolean;
}

const initialState: FeedbackState = {
  message: undefined,
  severity: MessageSeverity.info,
  open: false,
};

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    openSuccess: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
      state.severity = MessageSeverity.success;
      state.open = true;
    },
    openInfo: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
      state.severity = MessageSeverity.info;
      state.open = true;
    },
    openWarning: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
      state.severity = MessageSeverity.warning;
      state.open = true;
    },
    openError: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
      state.severity = MessageSeverity.error;
      state.open = true;
    },
    hideMessage: (state) => {
      state.message = undefined;
      state.open = false;
    },
  },
});

export const {hideMessage, openSuccess, openInfo, openWarning, openError} =
  feedbackSlice.actions;

export default feedbackSlice.reducer;
