import React, {ReactNode} from 'react';
import {useAppSelector} from '../store/hooks';
import {
  Card,
  CardActions,
  CardContent,
  Fab,
  Grid,
  Typography,
} from '@mui/material';
import {Add, ArrowCircleRight} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {Room} from '../models/Models';
import {Clickable} from '../components/Styles';

const Rooms = () => {
  const navigate = useNavigate();
  const {rooms} = useAppSelector((state) => state.reservation);
  const {user} = useAppSelector((state) => state.auth);

  const handleClick = (room: Room) => {
    navigate(`/occasions/${room.id}`);
  };
  const handleAdd = (room: Room) => {
    navigate(`/new-occasion/${room.id}`);
  };

  const AdminWall = ({
    children,
    onClick,
  }: {
    children: ReactNode;
    onClick: () => void;
  }) => {
    //<Clickable onClick={() => handleClick(room)}>
    if (user?.isAdmin) {
      return <>{children}</>;
    } else {
      return <Clickable onClick={onClick}>{children}</Clickable>;
    }
  };

  return (
    <Grid container spacing={2}>
      {rooms?.length &&
        rooms?.map((room) => (
          <Grid item xs={12} md={6} key={room.id}>
            <AdminWall onClick={() => handleClick(room)}>
              <Card>
                <CardContent>
                  <Typography variant="h4" gutterBottom>
                    {room.name}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Fab
                    color="secondary"
                    variant="extended"
                    onClick={() => handleClick(room)}
                  >
                    <ArrowCircleRight sx={{mr: 1}} />
                    Tovább
                  </Fab>
                  {user?.isAdmin && (
                    <Fab color="primary" onClick={() => handleAdd(room)}>
                      <Add />
                    </Fab>
                  )}
                </CardActions>
              </Card>
            </AdminWall>
          </Grid>
        ))}
    </Grid>
  );
};

export default Rooms;
